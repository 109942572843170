<template>
    <blockquote class="inline-block italic text-xl">
      <prismic-rich-text class="text-xl text-gray-500" :field="slice.primary.quote"/>
    </blockquote>
</template>

<script>
export default {
  props: ['slice'],
  name: 'quote-slice'
}
</script>